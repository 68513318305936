<template>
  <b-sidebar
    id="sidebar-edit-team-user-role"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Edit Team User Role</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer ref="editTeamUserRoleFormValidator" #default="{ invalid }">
        <b-form class="p-2" ref="editTeamUserRoleForm" @submit.prevent="editTeamUserRole">

          <b-form-group label="Role" label-for="role">
            <validation-provider
              #default="{ errors }"
              name="role"
              vid="role"
              rules="required"
            >
              <v-select
                v-model="role"
                :options="roles"
                id="role"
                label="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="(role) => role.value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>


          <!-- Form Actions -->
          <div class="d-flex align-items-center justify-content-between">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid || formIsLoading"
              :class="{ disabled: invalid }"

            >
              <b-spinner small v-if="formIsLoading" />
              &nbsp;
              Submit
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>

    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BButton,
    BSpinner,
    vSelect,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props:["id", "roleId","roles"],
  emits:["team-refresh", "reset-id"],
  data(){
    return{
      role: null,
      formIsLoading: false,
      required,
    };
  },
  methods: {
    editTeamUserRole() {
      this.formIsLoading = true;
      this.$refs.editTeamUserRoleFormValidator.validate().then(success => {
        if (success) {
          this.$http.put('/team/user/edit',{
            user_id:  this.id,
            role_id:  this.role,
          })
          .then(response => {
            this.formIsLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: response.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.$emit('team-refresh')
            this.$root.$emit('bv::toggle::collapse', 'sidebar-edit-team-user-role')
            this.role = null;
          })
          .catch(error => {
              for (let err of error.response.data.error) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title:err,
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
              }
              this.formIsLoading = false;
          })
        }
      });
    },
  },
  mounted(){
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if(collapseId == "sidebar-edit-team-user-role" && isJustShown){
        if(this.id && this.id != null && this.id != undefined && this.roleId && this.roleId != null && this.roleId != undefined){
          this.role = this.roleId;
        }
      }
      if(collapseId == "sidebar-edit-team-user-role" && !isJustShown){
        this.$emit('reset-id')
      }
    })
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

</style>