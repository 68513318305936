<template>
  <div>
    <skeleton-table v-if="initialize" :rows="10" :columns="fields.length" :buttons="1" :dateSearch="true" />

    <no-records-found
      v-if="showNotFound"
      text="No users found in your team"
      btnOneToggle="sidebar-add-user-to-team"
    >
      <template #btnOne>
        <feather-icon icon="PlusCircleIcon" class="mr-50" />
        <span class="align-middle">Add User to Team</span>
      </template>
    </no-records-found>

    <b-card v-else no-body class="action-buttons" :class="{'d-none': mainCardInvisiblity}">
      <b-row class="mb-1 mx-1">
        <b-col md="2" xl="4">
          <div class="inline-spacing">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="mr-50 mt-2"
              v-b-toggle.sidebar-add-user-to-team
            >
              <feather-icon icon="PlusCircleIcon" class="mr-50" />
              <span class="align-middle">Add User to Team</span>
            </b-button>
          </div>
        </b-col>

        <b-col md="10" xl="8" class="mt-2">
          <div class="d-flex justify-content-end align-items-baseline">
            
            <b-form-datepicker
              v-model="dateFrom"
              :max="maxDate"
              placeholder="Date from"
              reset-button
              class="rounded"
              locale="en"
            />

            <b-form-datepicker
              v-model="dateTo"
              :max="maxDate"
              placeholder="Date to"
              reset-button
              class="ml-2 rounded"
              locale="en"
            />

            <b-form-input
              v-model.trim="search"
              placeholder="Search for Team Users"
              class="ml-2"
            />

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon rounded-circle ml-1"
              @click="teamRefresh"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </div>

          <div class="text-right">
            <small v-if="dateError" class="text-danger">Both of date fields are required for date range</small>
          </div>
        </b-col>
      </b-row>

      <b-table
        show-empty
        empty-text="No records found from your search"
        empty-filtered-text="No records found from your search"
        hover
        striped
        :busy.sync="isBusy"
        :current-page="currentPage"
        :per-page="perPage"
        responsive
        :items="team"
        :fields="fields"
        ref="teamTable"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(name)="row">
          <span> {{ row.item.first_name }} {{ row.item.last_name }}</span>
        </template>

        <template #cell(phone)="row">
          <span> {{ row.item.code }}{{ row.item.phone }}</span>
        </template>

        <template #cell(status)="data">
          <b-button
            v-if="data.item.status == 'pending'"
            variant="outline-primary"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>

          <b-button
            v-else-if="data.item.status == 'accepted'"
            variant="outline-success"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>

          <b-button
            v-else-if="data.item.status == 'rejected'"
            variant="outline-danger"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>

          <b-button
            v-else
            variant="outline-primary"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>
        </template>

        <template #cell(actions)="row">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>

            <b-dropdown-item
              v-if="row.item.status == 'rejected'"
              v-b-modal.resend-add-user-to-team
              @click="resendRequest(row.item.ID, row.item.role_id, row.item.first_name, row.item.last_name)"
            >
              <feather-icon icon="RefreshCwIcon" class="mr-50" />
              <span>Resend Request</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="row.item.status != 'rejected'"
              @click="editRole(row.item.ID, row.item.role_id)"
            >
              <feather-icon icon="EditIcon" class="mr-50" />
              <span>Edit Role</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="row.item.status != 'pending'"
              v-b-modal.remove-team-user
              @click="removeUser(row.item.ID, row.item.first_name, row.item.last_name)"
            >
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span>Remove User</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="lg"
        v-if="!noRecordsFound"
      ></b-pagination>
    </b-card>
    <sidebar-add-user :countries="countries" :roles="roles" @team-refresh="teamRefresh" />
    <sidebar-edit-user-role :id="selectedId" :roleId="selectedRole" :roles="roles" @team-refresh="teamRefresh" @reset-id="resetId" />
    <remove-team-user :id="selectedId" :user="selectedUser" @team-refresh="teamRefresh"/>
    <resend-request :id="selectedId" :roleId="selectedRole" :user="selectedUser" @team-refresh="teamRefresh"/>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BPagination,
  BTable,
  BButton,
  BSpinner,
  BFormDatepicker,
  BCard,
  BFormInput,
  BFormCheckbox,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCol,
  BRow,
  VBToggle,
  VBModal,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import SidebarAddUser from "./components/SidebarAddUser.vue";
import SidebarEditUserRole from "./components/SidebarEditUserRole.vue";
import RemoveTeamUser from "./components/RemoveTeamUser.vue";
import ResendRequest from "./components/ResendRequest.vue";

import SkeletonTable from "@/views/pages/shimmers/SkeletonTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import NoRecordsFound from "@/views/pages/no-records-found/NoRecordsFound.vue";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    'b-tooltip': VBTooltip,
  },
  components: {
    BDropdown,
    BDropdownItem,
    BTable,
    BPagination,
    BCard,
    BButton,
    BSpinner,
    BFormDatepicker,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    BRow,

    SidebarAddUser,
    SidebarEditUserRole,
    RemoveTeamUser,
    ResendRequest,
    NoRecordsFound,
    SkeletonTable,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return {
      fields: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "name",
          label: "NAME",
        },
        {
          key: "email",
          label: "EMAIL",
        },
        {
          key: "phone",
          label: "PHONE",
        },
        {
          key: "title",
          label: "ROLE",
        },
        {
          key: "status",
          label: "STATUS",
        },
        {
          key: "format_created_at",
          label: "REGISTERED AT",
        },
        {
          key: "actions",
          label: "ACTIONS",
        },
      ],
      search: "",
      maxDate: new Date(today),
      dateFrom: null,
      dateTo: null,
      dateError: false,
      countries: [],
      roles: [],
      selectedId: false,
      selectedRole: false,
      selectedUser: false,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      initialize: true,
      showNotFound: false,
      noRecordsFound: false,
      mainCardInvisiblity:true,
    };
  },
  methods: {
    team(ctx, callback) {
      let params = "?page=" + ctx.currentPage;
      if (this.dateFrom && this.dateTo) {
        params += "&date_from=" + this.dateFrom + "&date_to=" + this.dateTo;
      }
     if (this.search) {
        params += "&search=" + this.search;
      }
      this.$http
        .get("/team/users" + params)
        .then((response) => {
          this.totalRows = response.data.total_pages * 10;
          // Pluck the array of items off our axios response
          const items = response.data.data;
          if (items.length == 0) {
            this.noRecordsFound = true;

            if (this.initialize) {
              this.showNotFound = true;
            }
          } else {
            this.noRecordsFound = false;
          }
          this.initialize = false;
          this.isBusy = false;
          this.mainCardInvisiblity = false;
          callback(items);
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          if (this.initialize) {
            this.showNotFound = true;
          }
          this.noRecordsFound = true;
          this.initialize = false;
          this.mainCardInvisiblity = false;
          callback([]);
          this.isBusy = false;
        });
      return null;
    },
    teamRefresh() {
      this.selectedId = false;
      this.selectedRole = false;
      this.selectedUser = false;
      if ((!this.dateFrom && this.dateTo) || (this.dateFrom && !this.dateTo)) {
        this.dateError = true;
      } else {
        this.dateError = false;
        this.$refs.teamTable.refresh();
      }
    },
    resetId() {
      this.selectedId = false;
      this.selectedRole = false;
      this.selectedUser = false;
    },
    resendRequest(id, roleId, firstName, lastName){
      this.selectedId = id;
      this.selectedRole = roleId;
      this.selectedUser = firstName + " " + lastName;
    },
    editRole(id, roleId){
      this.selectedId = id;
      this.selectedRole = roleId;
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit-team-user-role");
    },
    removeUser(id, firstName, lastName){
      this.selectedId = id;
      this.selectedUser = firstName + " " + lastName;
    },
  },
  created(){
    this.$http.get('/countries')
    .then(response => {
      for (const country of response.data.data) {
        let pushCountry = {
          value : country.ID,
          text: country.iso + " (" + country.code + ")",
        }
        this.countries.push(pushCountry);
      }
    })
    .catch(error => {
      for (let err of error.response.data.error) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title:err,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        }
    });

    this.$http.get('/team/roles')
    .then(response => {
      for (const role of response.data.data) {
        let pushRole = {
          value : role.ID,
          text: role.title,
        }
        this.roles.push(pushRole);
      }
    })
    .catch(error => {
      for (let err of error.response.data.error) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title:err,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        }
    });
  },
};
</script>