<template>
  <b-sidebar
    id="sidebar-add-user-to-team"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add User to Team</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer ref="addUserToTeamFormValidator" #default="{ invalid }">
        <b-form class="p-2" ref="addUserToTeamForm">

          <b-alert
            show
            dismissible
            fade
            variant="success"
            v-model="showAlert"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="InfoIcon"
              />
              <span class="ml-25" v-html="alertContent"></span>
            </div>
          </b-alert>

          <!-- email -->
          <b-form-group label="Email" label-for="email">
            <validation-provider
              #default="{ errors }"
              name="Email"
              vid="email"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="email"
                name="email"
                placeholder="john@example.com"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- phone number -->

          <b-form-group label="Phone Number" label-for="phone">
            <b-input-group class="shadow-none">
              <v-select
                v-model="country"
                :options="countries"
                label="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="(country) => country.value"
                class="col-6 col-g-0 p-0"
              />
              <validation-provider
                #default="{ errors }"
                name="Phone"
                vid="phone"
                rules="required|integer"
                class="col-6 col-g-0 pr-0"
              >
                <b-form-input
                  id="phone"
                  type="tel"
                  v-model="phone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="729542528"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger" v-if="invalidPhone">Invalid Phone Number</small>
              </validation-provider>
            </b-input-group>
          </b-form-group>

          <!-- first name -->
          <b-form-group label-for="firstName" label="First Name" v-if="exist == false">
            <validation-provider
              #default="{ errors }"
              name="First Name"
              vid="firstName"
              rules="required"
            >
              <b-form-input
                id="firstName"
                v-model="firstName"
                name="firstName"
                placeholder="John"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- last name -->
          <b-form-group label-for="lastName" label="Last Name" v-if="exist == false">
            <validation-provider
              #default="{ errors }"
              name="Last Name"
              vid="lastName"
              rules="required"
            >
              <b-form-input
                id="lastName"
                v-model="lastName"
                name="lastName"
                placeholder="Doe"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Role -->
          <b-form-group label="Role" label-for="role" v-if="alreadyMember == false">
            <validation-provider
              #default="{ errors }"
              name="role"
              vid="role"
              rules="required"
            >
              <v-select
                v-model="role"
                :options="roles"
                id="role"
                label="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="(role) => role.value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group class="mb-2" v-if="alreadyMember == false">
            <b-form-checkbox
              id="status"
              v-model="status"
              name="status"
              :value="true"
            >
              I agree to
              <b-link>privacy policy & terms</b-link>
            </b-form-checkbox>
            <small class="text-danger" v-if="!status && status != 'start'"
              >The Policy field is required</small
            >
          </b-form-group>


          <table class="table mt-2 mb-2" v-if="exist">
            <h4>User Details</h4>
            <tbody>
              <tr>
                <td>Name</td>
                <th>{{ user.first_name }}  {{user.last_name}}</th>
              </tr>
              <tr>
                <td>Email</td>
                <th>{{user.email}}</th>
              </tr>
              <tr>
                <td>Phone Number</td>
                <th>{{user.code}}{{user.phone}}</th>
              </tr>
              <tr>
                <td>Already Your Team Member</td>
                <th v-if="alreadyMember" class="text-success">Yes</th>
                <th v-else class="text-danger">No</th>
              </tr>
              <tr v-if="alreadyMember">
                <td>Role</td>
                <th>{{user.title}}</th>
              </tr>
              <tr v-if="alreadyMember">
                <td>Status</td>
                <th v-if="user.status == 'pending'" class="text-primary text-capitalize">{{user.status}}</th>
                <th v-else-if="user.status == 'accepted'" class="text-success text-capitalize">{{user.status}}</th>
                <th v-else-if="user.status == 'rejected'" class="text-danger text-capitalize">{{user.status}}</th>
                <th v-else class="text-primary text-capitalize">{{user.status}}</th>
              </tr>
              <tr v-if="alreadyMember">
                <td>Registered At</td>
                <th>{{user.format_created_at}}</th>
              </tr>
            </tbody>
          </table>
         


          <!-- Form Actions -->
          <div class="d-flex align-items-center justify-content-between">
            <transition name="fade" mode="out-in">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                v-if="intialize"
                :disabled="invalid || invalidPhone || formIsLoading"
                :class="{ disabled: invalid }"
                @click="userExistanceCheck"

              >
                <b-spinner small v-if="formIsLoading" />
                &nbsp;
                Check
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                v-else-if="exist && alreadyMember == false"
                :disabled="invalid || invalidPhone || formIsLoading || (!status || status == 'start')"
                :class="{ disabled: invalid }"
                @click="addUserToTeam"

              >
                <b-spinner small v-if="formIsLoading" />
                &nbsp;
                Add User
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                v-else-if="exist == false && alreadyMember == false"
                :disabled="invalid || invalidPhone || formIsLoading || (!status || status == 'start')"
                :class="{ disabled: invalid }"
                @click="registerAddUserToTeam"

              >
                <b-spinner small v-if="formIsLoading" />
                &nbsp;
                Add User
              </b-button>

              <span
                v-else
              >
              </span>
            </transition>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>

    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, integer } from "@validations";

import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BButton,
  BLink,
  BSpinner,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BButton,
    BLink,
    BSpinner,
    BAlert,
    vSelect,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props:["countries", "roles"],
  emits:["team-refresh"],
  computed: {
    invalidPhone(){
      if(this.phone == "" || this.phone == null){
        return false;
      }
      let phoneSanitize = this.phone
      phoneSanitize = phoneSanitize.replaceAll(" ", '')
      if(phoneSanitize.substr(0, 1) == "+"){
        phoneSanitize = phoneSanitize.substr(1)
      }
      if(phoneSanitize.substr(0, 2) == "00"){
        phoneSanitize = phoneSanitize.substr(2)
      }
      if(phoneSanitize.substr(0, 1) == "0"){
        phoneSanitize = phoneSanitize.substr(1)
      }
      if(phoneSanitize.substr(0, 3) == "254"){
        phoneSanitize = phoneSanitize.substr(3)
      }

      if (phoneSanitize.length == 9) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch:{
    email(){
      this.resetForm(false);
    },
    phone(){
      this.resetForm(false);      
    },
    country(){
      this.resetForm(false);
    },
  },
  data(){
    return{
      email: "",
      phone: "",
      country: null,
      firstName: "",
      lastName: "",
      role: null,
      status: "start",
      exist: null,
      existField: null,
      alreadyMember: null,
      user: {},
      showAlert: false,
      alertContent: null,
      intialize: true,
      formIsLoading: false,
      required,
      email,
      integer,
    };
  },
  methods: {
    userExistanceCheck() {
      this.formIsLoading = true;
      this.$refs.addUserToTeamFormValidator.validate().then(success => {
        if (success) {
          this.$http.post('/team/users/check',{
            email: this.email,
            country_id: this.country,
            phone: this.phone,
          })
          .then(response => {

            let data = response.data.data;
            this.alreadyMember    = data.already_member;
            this.existField       = data.exist_field;
            this.exist            = data.exist;
            this.user             = data.user;
            if(this.exist){
              this.showAlert      = true;

              if(this.alreadyMember){
                this.alertContent = "The user is already a member of your team";
              } else {
                this.alertContent = "The user <strong>" + this.existField + "</strong> already exist in our system. You can add this user to your team.";
              }
            } else {
              this.showAlert      = false;
            }

            this.formIsLoading = false;
            this.intialize = false;
          })
          .catch(error => {
              for (let err of error.response.data.error) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title:err,
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
              }
              this.formIsLoading = false;
          })
        }
      });
    },
    addUserToTeam(){
      this.formIsLoading = true;
      this.$refs.addUserToTeamFormValidator.validate().then(success => {
        if (success) {
          this.$http.post('/team/user/add',{
            user_id: this.user.ID,
            role_id: this.role,
          })
          .then(response => {
            this.formIsLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: response.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.$emit('team-refresh')
            this.$root.$emit('bv::toggle::collapse', 'sidebar-add-user-to-team')
             this.$nextTick(() => {
              this.$refs.addUserToTeamFormValidator.reset();
            });
            this.$refs.addUserToTeamForm.reset();
            this.resetForm();
          })
          .catch(error => {
              for (let err of error.response.data.error) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title:err,
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
              }
              this.formIsLoading = false;
          })
        }
      });
    },
    registerAddUserToTeam(){
      this.formIsLoading = true;
      this.$refs.addUserToTeamFormValidator.validate().then(success => {
        if (success) {
          this.$http.post('/team/user/register',{
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            phone: this.phone,
            country_id: this.country,
            role_id: this.role,
          })
          .then(response => {
            this.formIsLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: response.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.$emit('team-refresh')
            this.$root.$emit('bv::toggle::collapse', 'sidebar-add-user-to-team')
             this.$nextTick(() => {
              this.$refs.addUserToTeamFormValidator.reset();
            });
            this.$refs.addUserToTeamForm.reset();
            this.resetForm();
          })
          .catch(error => {
              for (let err of error.response.data.error) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title:err,
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
              }
              this.formIsLoading = false;
          })
        }
      }); 
    },
    resetForm(everything = true){
      if(everything){
        this.email        = "";
        this.phone        = "";
        this.country      = null;
      }
      this.firstName      = "";
      this.lastName       = "";
      this.role           = null;
      this.status         = "start";
      this.exist          = null;
      this.existField     = null;
      this.alreadyMember  = null;
      this.user           = {};
      this.showAlert      = false;
      this.alertContent   = null;
      this.intialize      = true;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

</style>