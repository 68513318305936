<template>
  <b-modal
    id="resend-add-user-to-team"
    centered
    title="Resend Request To Add User To Team"
    hide-footer
  >
    <b-card-text> Are you sure you want to resent the request to add <strong>{{user}}</strong> user to your team? </b-card-text>

     <div class="text-right">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="secondary"
        @click="$bvModal.hide('resend-add-user-to-team')"
        class="mr-2"
      >
        Cancel
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        :disabled="isLoading"
        @click="resendAddUserToTeam"
      >
        <b-spinner class="mr-1" small v-if="isLoading" />
        Resend Request
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BCardText,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BModal,
    BCardText,
    BButton,
    BSpinner,
  },
  props: ["id", "roleId", "user"],
  emits: ["team-refresh"],
  data(){
    return{
      isLoading: false,
    };
  },
  methods:{
    resendAddUserToTeam(){
      this.isLoading = true;
      this.$http.post('/team/user/add',{
        user_id: this.id,
        role_id: this.roleId,
      })
      .then(response => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: response.data.message,
            icon: "CheckIcon",
            variant: "success",
          },
        });
        this.$emit('team-refresh');
        this.$bvModal.hide("resend-add-user-to-team");
        this.isLoading = false;
      })
      .catch(error => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title:err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          this.isLoading = false;
      })
    
    }
  }
};
</script>
